
// 底层组件库
import barOption from './components/bar'
import datetimeOption from './components/datetime'
import flopOption from './components/flop'
import funnelOption from './components/funnel'
import gaugeOption from './components/gauge'
import iframeOption from './components/iframe'
import imgOption from './components/img'
import imgborderOption from './components/imgborder'
import lineOption from './components/line'
import mapOption from './components/map'
import pictorialbarOption from './components/pictorialbar'
import pieOption from './components/pie'
import progressOption from './components/progress'
import radarOption from './components/radar'
import scatterOption from './components/scatter'
import swiperOption from './components/swiper'
import tableOption from './components/table'
import tabsOption from './components/tabs'
import textOption from './components/text'
import videoOption from './components/video'
import commonOption from './components/common'
import wordcloudOption from './components/wordcloud'
import datavOption from './components/datav'
//通用配置
import mainOption from './components/main'
//自定义组件库
import testOption from '@/components/test/option'
import numdiyOption from '@/components/numdiy/option'
import productionvalueOption from '@/components/productionvalue/option'
import companymapOption from '@/components/companymap/option'
import companynameOption from '@/components/companyname/option'
import linkabletableOption from '@/components/linkabletable/option'
import delievercompanymapOption from '@/components/delivercompanymap/option'

export default {
  components: {
    commonOption,
    mainOption,
    barOption,
    datetimeOption,
    flopOption,
    funnelOption,
    gaugeOption,
    iframeOption,
    imgOption,
    imgborderOption,
    lineOption,
    mapOption,
    pictorialbarOption,
    pieOption,
    progressOption,
    radarOption,
    scatterOption,
    swiperOption,
    tableOption,
    tabsOption,
    textOption,
    videoOption,
    wordcloudOption,
    datavOption,
    testOption,
    numdiyOption,
    companymapOption,
    linkabletableOption,
    companynameOption,
    productionvalueOption,
    delievercompanymapOption
  }
}